import {
  PlatformWorkspaceConfig,
  PlatformWorkspaceContent
} from "modules/Platform/src/core/types"
import { createContext, useContext } from "react"
import { Outlet, useParams } from "react-router-dom"
import { usePlatformWorkspaceConfig } from "../modules/Platform/src/hooks"
import { EmptyObject } from "../global-types"
import { Workspace } from "../modules/Workspace/src/core/types"

type PlatformWorkspaceContext = {
  platformWorkspaceConfig: undefined | PlatformWorkspaceConfig
  workspace: undefined | Workspace
}

export const PlatformWorkspaceContext =
  createContext<PlatformWorkspaceContext>({
    platformWorkspaceConfig: undefined,
    workspace: undefined
  })

const PlatformWorkspaceProvider = () => {
  const { platformWorkspaceConfig, workspace } = usePlatformWorkspaceConfig()

  return (
    <PlatformWorkspaceContext.Provider value={{ platformWorkspaceConfig, workspace }}>
      <Outlet />
    </PlatformWorkspaceContext.Provider>
  )
}

export default PlatformWorkspaceProvider

export const usePlatformWorkspaceState = (): PlatformWorkspaceContext => {
  const context = useContext(PlatformWorkspaceContext)


  if (!context) {
    throw new Error(
      "usePlatformWorkspaceState must be used within a PlatformWorkspaceProvider"
    )
  }

  return context
}

export const usePlatformWorkspaceContent = (): PlatformWorkspaceContent | EmptyObject => {
  const { platformWorkspaceConfig } = usePlatformWorkspaceState()
  const { lang = "en" } = useParams()
  const content:
    { [key: string]: PlatformWorkspaceContent | EmptyObject } = {
      en: platformWorkspaceConfig?.contentEn || {},
      ru: platformWorkspaceConfig?.contentRu || {},
      am: platformWorkspaceConfig?.contentAm || {}
    }

  return content[lang] as PlatformWorkspaceContent | EmptyObject
}

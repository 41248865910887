import { Box, Grid, Image, Link } from "@chakra-ui/react"
import React from "react"
import { IconButton } from "theme/components"

const Layout = ({ children, onContractCreateClick, platformConfig }) => (
  <Grid
    className="h-screen"
    templateColumns={platformConfig.showSidebar ? "80px 1fr" : "1fr"}
    // bgImage="/assets/images/background.jpg"
    bgColor="#f3f3f3"
    bgSize="cover"
  >
    {platformConfig.showSidebar ? (
      <MenuSidebar
        onCreateClick={onContractCreateClick}
        platformConfig={platformConfig}
      />
    ) : null}

    <Box overflowY="auto">
      <Grid
        maxW="1440px"
        px="12px"
        height="full"
        pt={6}
        overflow="auto"
        templateColumns="358px minmax(680px, auto)"
        columnGap={4}
      >
        {children}
      </Grid>
    </Box>
  </Grid>
)

export default Layout

const MenuSidebar = ({ onCreateClick, platformConfig }) => (
  <Grid
    roundedRight="16px"
    shadow="4px 0px 70px rgba(0, 0, 0, 0.05)"
    bgColor="white"
    templateRows="80px 1fr auto"
  >
    {platformConfig.showLogo ? <CosmoLogo /> : null}

    <Grid justifyContent="center" pt="5">
      {onCreateClick ? (
        <IconButton
          iconName="plus"
          bgColor="primaryPurple"
          rounded="full"
          size="md"
          _hover={{ bgColor: "primaryPurple" }}
          _active={{ bgColor: "primaryPurple" }}
          onClick={onCreateClick}
          shadow="0px 4px 14px rgba(100, 87, 249, 0.5)"
        />
      ) : null}
    </Grid>
  </Grid>
)

const CosmoLogo = () => (
  <Grid
    height="80px"
    alignItems="center"
    justifyContent="center"
    px="3"
    position="relative"
    _after={{
      content: '""',
      bgColor: "#ededed",
      height: "1px",
      position: "absolute",
      bottom: "0",
      left: "3",
      right: "3"
    }}
  >
    <Link
      target="_blank"
      href="https://cosmoplatform.com/"
      rel="noopener noreferrer"
    >
      <Image src="/assets/images/logo-symbol.png" width="36px" height="36px" />
    </Link>
  </Grid>
)

import { Link, Text, VStack } from "@chakra-ui/react"
import {
  useSignIn,
  useSignInFacebook,
  useSignInGoogle
} from "modules/User/src/core/hooks"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { AuthLayout, Button } from "theme/components"
import SignInForm from "./SignInForm"
// import ReactGA from "react-ga4"
// import gtag from "ga-gtag"

function SignIn() {
  const { mutateAsync: signInAsync, isLoading: isLoadingSignIn } = useSignIn()
  const { mutate: signInGoogle } = useSignInGoogle()
  const { mutate: signInFacebook } = useSignInFacebook()

  const handleSignIn = async (values, { onError }) => {
    try {
      // gtag("event", "sign_in_attempt", {
      //   event_category: "User",
      //   event_label: "Sign in button",
      //   value: 1
      // })
      // ReactGA.event({
      //   category: "User",
      //   action: "Sign In Attempt",
      //   label: "Sign in button",
      //   value: 1
      // })
      await signInAsync(values)
    } catch (error) {
      if (!error?.code) {
        return
      }

      onError(error.message)
    }
  }

  const handleGoogleSignIn = () => signInGoogle()

  const handleFacebookSignIn = () => signInFacebook()

  return (
    <AuthLayout headerText="Log in to your account">
      <SignInForm
        onSignIn={handleSignIn}
        isLoadingSignIn={isLoadingSignIn}
      />

      <SocialSignIn
        onGoogleSignIn={handleGoogleSignIn}
        onFacebookSignIn={handleFacebookSignIn}
      />

      <SignInFooter />
    </AuthLayout>
  )
}

export default SignIn

function SocialSignIn({ onGoogleSignIn, onFacebookSignIn }) {
  return (
    <VStack spacing="4" mt="10">
      <Button
        variant="outline"
        w="full"
        onClick={onGoogleSignIn}
        iconName="google"
      >
        Sign in with Google
      </Button>

      <Button
        variant="outline"
        w="full"
        onClick={onFacebookSignIn}
        iconName="facebook"
      >
        Sign in with Facebook
      </Button>
    </VStack>
  )
}

function SignInFooter() {
  return (
    <VStack spacing="1" mt="6">
      <Text fontSize="md" align="center">
        No account?{" "}
        <Link as={RouterLink} fontWeight="bold" to="/sign-up">
          Create one
        </Link>
      </Text>

      <Text>
        <Link
          as={RouterLink}
          to="/reset-password"
          fontWeight="bold"
          fontSize="md"
          align="center"
        >
          Reset password
        </Link>
      </Text>
    </VStack>
  )
}

import { useQueryParams } from "providers/QueryParamsProvider"
import {
  ConnectedSourceProject,
  SourceProjectEditProps,
  UseSourceSettingsOptions
} from "modules/Source/src//core/types/hooks"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSourceSettingsDiscord } from "./discord"
import { useSourceSettingsJira } from "./jira"
import { useSourceSettingsSlack } from "./slack"
import { ModalType } from "../../../../../../providers/ModalProvider/types"

export const useSourceSettings = ({
  workspace,
  workspaceContracts,
  user
}: UseSourceSettingsOptions) => {
  const { setQueryParams, removeQueryParams, queryParams } = useQueryParams()

  const { dispatch: modalDispatch, state: modalState } = useModalContext()
  const isMobile = useIsMobile()

  const [projectEditProps, setProjectEditProps] =
    useState<SourceProjectEditProps | null>(null)

  const projectEditPropsMiddleware = (
    projectEditProps: SourceProjectEditProps | null
  ) => {
    if (projectEditProps) {
      const onUpdate = projectEditProps.onUpdate
        ? handleProjectUpdate(projectEditProps.onUpdate)
        : undefined

      return {
        ...projectEditProps,
        onUpdate,
        onDisconnect: handleProjectDisconnect(projectEditProps.onDisconnect)
      }
    }

    return null
  }

  const jira = useSourceSettingsJira({
    workspace,
    workspaceContracts,
    user
  })
  const discord = useSourceSettingsDiscord({
    workspace,
    user
  })
  const slack = useSourceSettingsSlack({
    workspace,
    user
  })

  const handleProjectEdit = (project: ConnectedSourceProject) => {
    setQueryParams({
      tab: "source",
      sourceType: project.sourceType,
      projectId: project.id
    })
  }

  const isSourceEditModalOpen = useMemo(
    () => modalState.modalType === "sourceEdit",
    [modalState.modalType]
  )

  // on resize close don't remove query params
  const handleEditModalClose = useCallback(() => {
    setProjectEditProps(null)

    if (!isMobile) {
      removeQueryParams("sourceType", "projectId")
    }
  }, [isMobile, removeQueryParams])

  useEffect(() => {
    if (!queryParams.sourceType && !queryParams.projectId) {
      setProjectEditProps(null)

      if (isSourceEditModalOpen) {
        modalDispatch({ type: "close" })
      }
    } else if (queryParams.sourceType && queryParams.projectId) {
      let normalizedEditProps
      const methods = {
        discord: discord.getChannelEditProps,
        jira: jira.getProjectEditProps,
        slack: slack.getChannelEditProps
      }
      const sourceType = queryParams.sourceType as keyof typeof methods
      const editProps = methods[sourceType](queryParams.projectId)

      if (editProps) {
        normalizedEditProps = projectEditPropsMiddleware(editProps)
        setProjectEditProps(normalizedEditProps)
      }

      if (!isMobile && normalizedEditProps) {
        modalDispatch({
          type: "open",
          modalType: ModalType.SourceEdit,
          props: normalizedEditProps,
          onClose: handleEditModalClose
        })
      }

      if (isSourceEditModalOpen) {
        modalDispatch({
          type: "update",
          onClose: handleEditModalClose
        })
      }
    }
  }, [
    JSON.stringify(queryParams),
    isSourceEditModalOpen,
    handleEditModalClose,
    jira.getProjectEditProps
  ])

  const handleProjectUpdate =
    (onUpdate: SourceProjectEditProps["onUpdate"]) =>
      async (projectId: string, projectLabels: string[]) => {
        if (onUpdate) {
          await onUpdate(projectId, projectLabels)
        }
        handleEditModalClose()
      }

  const handleProjectDisconnect =
    (onDisconnect: SourceProjectEditProps["onDisconnect"]) =>
      async (projectId: string) => {
        await onDisconnect(projectId)
        handleEditModalClose()
      }

  // source settings drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const toggleDrawer = () => setIsOpenDrawer(prevState => !prevState)
  const openDrawer = () => setIsOpenDrawer(true)
  const closeDrawer = () => setIsOpenDrawer(false)
  const onDrawerClose = () => {
    setIsOpenDrawer(false)
  }

  // source settings drawer

  // connected projects
  const connectedProjects = useMemo(
    () => [
      ...jira.connectedProjects,
      ...discord.connectedChannels,
      ...slack.connectedChannels
    ],
    [
      jira.connectedProjects.length,
      discord.connectedChannels.length,
      slack.connectedChannels.length
    ]
  )

  // connected projects

  return {
    source: {
      projectEditProps,
      handleProjectEdit,
      connectedProjects
    },
    jira,
    discord,
    slack,
    isOpenDrawer,
    onDrawerClose,
    toggleDrawer,
    openDrawer,
    closeDrawer
  }
}

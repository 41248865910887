import { useQueryParams } from "providers/QueryParamsProvider"
import ReactGA from "react-ga4"
import { EmptyObject, Workspace } from "modules/Workspace/src//core/types"
import {
  DEFAULT_TEAM_EMOJI,
  DELEGATE_TEAM_GROUPS,
  EMOJI_BG_COLORS
} from "modules/Workspace/src/core/config"
import { useGetWorkspaceTeamByIds } from "modules/Workspace/src/core/hooks"
import {
  WorkspaceTeam,
  WorkspaceTeamCommunityType,
  WorkspaceTeamOption,
  WorkspaceTeamOptionsGroup
} from "modules/Workspace/src/core/types/workspace-team"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { usePreviousPath } from "providers/PreviousPathProvider"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ContractWorkspaceTeam, IContractNormalized } from "../../types"
import { useDelegateContract } from "../useDelegateContract"
import { ModalType } from "../../../../../../providers/ModalProvider/types"
import {
  usePlatformWorkspaceContent
} from "../../../../../../providers/PlatformWorkspaceProvider"
import {
  DelegateModalContent
} from "../../../../../Platform/src/core/types"
import { useQueryClient } from "react-query"
import { useToast } from "@chakra-ui/react"

const useWorkspaceContractDelegate = ({
  workspaceContracts = [],
  workspaceTeams = [],
  workspace
}: Props) => {
  const { queryParams, setQueryParams } = useQueryParams()
  const toast = useToast()
  const isMobile = useIsMobile()
  const previousPath = usePreviousPath()
  const navigate = useNavigate()
  const {contractDetails} = usePlatformWorkspaceContent()

  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  // get delegate contract
  const [contractPk, setContractPk] = useState<string | null>(null)
  const workspaceContract = useMemo(
    () =>
      workspaceContracts.find(contract => contract.pk === contractPk) || null,
    [JSON.stringify(workspaceContracts), contractPk]
  )
  const queryClient = useQueryClient()
  const contract = useMemo(() => {
    if (!workspaceContract) {
      return null
    }

    return queryClient.getQueryData<IContractNormalized | null>(["mappedContract", workspaceContract.id])
  }, [workspaceContract?.id])

  const { data: predefinedTeams = [] } = useGetWorkspaceTeamByIds(
    contract?.predefinedTeamIds || []
  )

  const {
    mutateAsync: delegateContractAsync,
    isLoading: isLoadingDelegate
  } = useDelegateContract()

  const [delegateTeamOptions, setDelegateTeamOptions] = useState<
    WorkspaceTeamOption[]
  >([])
  useEffect(() => {
    const newDelegateTeamOptions: WorkspaceTeamOption[] = []

    if (contractPk) {
      const contractTeams = [...(contract?.teams || [])].filter(
        team => !workspaceTeams.find(t => t.teamId === team.teamId)
      )

      const filteredPredefinedTeams = predefinedTeams.filter(
        predefinedTeam =>
          !contractTeams.find(
            contractTeam => contractTeam.teamId === predefinedTeam.teamId
          )
      )

      const filteredWorkspaceTeams = workspaceTeams.filter(
        team =>
          !contractTeams.find(
            contractTeam => contractTeam.teamId === team.teamId
          ) &&
          !filteredPredefinedTeams?.find(
            predefinedTeam => predefinedTeam.teamId === team.teamId
          )
      )
      const delegateContractTeamOptions = [
        ...filteredWorkspaceTeams,
        ...contractTeams,
        ...filteredPredefinedTeams
      ]

      const isOneEditableTeam = delegateContractTeamOptions.filter(
        team => !contractTeams.find(
          contractTeam => contractTeam.teamId === team.teamId
        )
      ).length === 1

      const delegateContractTeamOptionsNormalized = delegateContractTeamOptions.map(
        (workspaceTeam) => {
          const contractTeam = contract?.teams?.find(
            team => team.teamId === workspaceTeam.teamId
          )
          const team: WorkspaceTeam | ContractWorkspaceTeam =
            contractTeam || workspaceTeam
          // make editable only user teams
          // const isEditable = contractTeam?.isEditable ?? true
          const name =
            team.communityType === WorkspaceTeamCommunityType.EMAIL
              ? team.teamId
              : team.name

          const isPredefined = !!predefinedTeams.find(
            predefinedTeam => predefinedTeam.id === team.id
          )

          return {
            id: team.id,
            name,
            emoji: team?.emoji || DEFAULT_TEAM_EMOJI,
            emojiBgColor: team?.emojiBgColor || EMOJI_BG_COLORS[7],
            type: team.type,
            isSelected: !!contractTeam || isOneEditableTeam,
            isPredefined,
            showIsPrivate:
              team.communityType === WorkspaceTeamCommunityType.DISCORD,
            isPrivate: ("isPrivate" in team && team.isPrivate) || false,
            isEditable: !contractTeam
          }
        })

      newDelegateTeamOptions.push(...delegateContractTeamOptionsNormalized)
    }

    setDelegateTeamOptions(newDelegateTeamOptions)
  }, [
    contractPk,
    JSON.stringify(workspaceTeams),
    JSON.stringify(predefinedTeams)
  ])

  const delegateTeamOptionsGroups = useMemo<WorkspaceTeamOptionsGroup[]>(
    () =>
      DELEGATE_TEAM_GROUPS.map(group => {
        const teamOptions =
          delegateTeamOptions?.filter(
            team =>
              group.types.includes(team.type) &&
              !!group.isPredefined === team.isPredefined
          ) || []

        return {
          ...group,
          teamOptions
        }
      }),
    [JSON.stringify(delegateTeamOptions)]
  )

  const handleDelegateTeamOptionChange = (newTeam: WorkspaceTeamOption) => {
    const newTeamOptions = delegateTeamOptions.map(team => {
      if (team.id === newTeam.id) {
        return {
          ...team,
          ...newTeam
        }
      }

      return team
    })

    setDelegateTeamOptions(newTeamOptions)
  }

  const handleDelegate = async () => {
    ReactGA.event({
      category: "Contract",
      action: "Delegate Contract"
    })
    const delegateWorkspaceTeams = delegateTeamOptions
      .filter(team => team.isSelected)
      .map(team => ({
        id: team.id,
        isPrivate: team.isPrivate
      }))
      .filter(
        workspaceTeam =>
          !contract?.teams?.find(team => team.id === workspaceTeam.id)
      )

    if (!delegateWorkspaceTeams.length) {
      return closeDelegate()
    }

    await delegateContractAsync({
      contractId: contract?.id || "",
      input: {
        workspaceTeams: delegateWorkspaceTeams
      },
      workspaceId: workspace.id
    })

    toast({
      title: contractDetails?.acceptedSuccessMessage || "Contract delegated successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right"
    })

    ReactGA.event({
      category: "Contract",
      action: "Delegate Contract Success"
    })

    closeDelegate()
  }

  const { delegateModal } = usePlatformWorkspaceContent()

  const contractDelegateProps = useMemo(
    (): DelegateProps => ({
      teamOptionsGroups: delegateTeamOptionsGroups,
      onTeamOptionChange: handleDelegateTeamOptionChange,
      onDelegate: handleDelegate,
      isLoadingDelegate,
      content: delegateModal
    }),
    [
      JSON.stringify(delegateTeamOptionsGroups),
      handleDelegateTeamOptionChange,
      JSON.stringify(delegateModal),
      isLoadingDelegate
    ]
  )

  const handleDelegateClick = (contractPk: string) => {
    setContractPk(contractPk)
    setQueryParams({
      tab: "contract-delegate"
    })
  }

  const isDelegateModalOpen = useMemo(
    () => modalState.modalType === ModalType.PlatformContractDelegate,
    [modalState.modalType]
  )

  const closeDelegate = useCallback(() => {
    setContractPk(null)
    navigate(previousPath || "./")
    if (isDelegateModalOpen) {
      modalDispatch({ type: "close" })
    }
  }, [previousPath])

  useEffect(() => {
    if (queryParams.tab === "contract-delegate" && !isMobile && contractDelegateProps) {
      modalDispatch({
        type: "open",
        modalType: ModalType.PlatformContractDelegate,
        props: contractDelegateProps,
        onClose: closeDelegate
      })
    }

    if (isDelegateModalOpen) {
      modalDispatch({
        type: "update",
        props: contractDelegateProps,
        onClose: closeDelegate
      })
    }
  }, [
    JSON.stringify(queryParams),
    JSON.stringify(contractDelegateProps),
    isDelegateModalOpen,
    isMobile
  ])

  return {
    handleDelegateClick,
    contractDelegateProps
  }
}

export default useWorkspaceContractDelegate

type Props = {
  workspaceContracts: IContractNormalized[]
  workspace: Workspace | EmptyObject
  workspaceTeams: WorkspaceTeam[]
}

export type DelegateProps = {
  teamOptionsGroups: WorkspaceTeamOptionsGroup[]
  onTeamOptionChange: (newTeam: WorkspaceTeamOption) => void
  onDelegate: () => void
  isLoadingDelegate: boolean
  content: DelegateModalContent
}

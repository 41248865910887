import { useGetCurrentUser } from "modules/User/src/core/hooks"
import { useIsMobile } from "providers/BreakpointProvider"
import { useModalContext } from "providers/ModalProvider"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useWorkspaceTeams } from "../useWorkspaceTeams"
import useWorkspaceCDE from "./cde"
import useWorkspaceCommunity from "./community"
import { useWorkspaceContract } from "./contract"
import useWorkspaceSource from "./source"
import { useWorkspaceActions } from "./useWorkspaceActions"
import { useQueryParams } from "providers/QueryParamsProvider"

export const useWorkspace = () => {
  const isMobile = useIsMobile()
  const { data: user = null } = useGetCurrentUser()
  const { dispatch: modalDispatch } = useModalContext()
  const { setQueryParams } = useQueryParams()

  const {
    workspace,
    activeTab,
    workspaceDeleteProps,
    openWorkspaceDeleteModal,
    handleArchivedContractsClick,
    handleArchivedContractsBackClick
  } = useWorkspaceActions()

  const {
    workspaceContracts,
    contractPk: activeContractPk,
    toggleCompleted: toggleContractCompleted,
    contractDetailsProps,
    handlePointEstimationChange: handleContractPointEstimationChange,
    handleDelegateClick,
    handleContractCreate,
    handleContractCreateClick,
    contractDelegateProps,
    contractSettingsProps,
    isArchivedContractsTab,
    handleContractCardClick
  } = useWorkspaceContract({
    user,
    workspace: workspace || {},
    activeTab,
    isMobile
  })

  const { communitySettingsProps, handleCommunitySettingsClick } =
    useWorkspaceCommunity({
      workspace,
      user
    })

  const { sourceSettingsProps, handleSourceSettingsClick } = useWorkspaceSource(
    {
      workspace: workspace,
      workspaceContracts: workspaceContracts,
      user
    }
  )

  const { CDESettingsProps, handleCDESettingsClick } = useWorkspaceCDE({
    workspace: workspace,
    user: user
  })

  const { workspaceTeamsGroups, handleTeamEdit, workspaceTeamEditProps } =
    useWorkspaceTeams(workspace?.id || "")

  // set contractPk to first contractPk
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isMobile) {
      return
    }

    const contractPk = searchParams.get("contractPk")
    let isContractPkExist = false

    if (contractPk) {
      workspaceContracts.forEach(contract => {
        if (contract.pk === contractPk) {
          isContractPkExist = true
        }
      })
    }

    if (!isContractPkExist && workspaceContracts.length > 0) {
      searchParams.set("contractPk", workspaceContracts[0].pk)
      navigate(`?${searchParams.toString()}`)
    }
  }, [searchParams, workspaceContracts.length, isMobile])

  // set contractPk to first contractPk

  useEffect(() => {
    if (isMobile) {
      modalDispatch({ type: "close" })
    }
  }, [isMobile])

  const handleChatBackClick = () => setQueryParams({ tab: "contract" })

  return {
    contractSidebarProps: {
      contracts: workspaceContracts,
      activeContractPk,
      toggleContractCompleted,
      onPointEstimationChange: handleContractPointEstimationChange,
      onDelegateClick: handleDelegateClick,
      isArchivedContractsTab,
      onArchivedContractsBackClick: handleArchivedContractsBackClick,
      onContractCardClick: handleContractCardClick
    },
    teamsProps: {
      teamsGroups: workspaceTeamsGroups,
      onTeamEdit: handleTeamEdit
    },
    sourceSettingsProps,
    communitySettingsProps,
    CDESettingsProps,
    workspaceTeamEditProps,
    handleContractCreate,
    handleCDESettingsClick,
    handleCommunitySettingsClick,
    handleSourceSettingsClick,
    handleContractCreateClick,
    handleArchivedContractsClick,
    openWorkspaceDeleteModal,
    workspace,
    contractDetailsProps,
    contractDelegateProps,
    contractSettingsProps,
    workspaceDeleteProps,
    isMobile,
    activeTab,
    user,
    handleChatBackClick
  }
}

import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  ReactNode
} from "react"
import { useLocation, useNavigate } from "react-router-dom"

// Create the QueryParams Context
const QueryParamsContext = createContext({
  queryParams: {} as Record<string, string>,
  setQueryParams: (params: Record<string, string>) => {},
  removeQueryParams: (...params: string[]) => {}
})

// Provider component
export const QueryParamsProvider = ({ children }: {children: ReactNode | ReactNode[]}) => {
  const navigate = useNavigate()
  const location = useLocation()

  // Retrieve query params from the URL and convert them into an object
  const getSearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(location.search)
    const params: Record<string, string> = {}

    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }

    return params
  }, [location.search])

  // Memoize the queryParams object
  const queryParams = useMemo(getSearchParams, [getSearchParams])

  // Set query params by updating the queryParams object directly and converting it into a search string
  const setQueryParams = useCallback(
    (params: Record<string, string>) => {
      const searchParams = new URLSearchParams(window.location.search) // Use window.location.search directly

      for (const [key, value] of Object.entries(params)) {
        searchParams.set(key, value)
      }

      navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true })
    },
    [navigate]
  )

  // Remove specific query params from the queryParams object and update the URL
  const removeQueryParams = useCallback(
    (...params: string[]) => {
      const updatedParams = { ...queryParams }

      for (const param of params) {
        delete updatedParams[param]
      }

      const searchParamsString = new URLSearchParams(updatedParams).toString()

      navigate(`${location.pathname}?${searchParamsString}`, { replace: true })
    },
    [navigate, location.pathname, queryParams]
  )

  return (
    <QueryParamsContext.Provider value={{ queryParams, setQueryParams, removeQueryParams }}>
      {children}
    </QueryParamsContext.Provider>
  )
}

export const useQueryParams = () => {
  const context = useContext(QueryParamsContext)

  if (!context) {
    throw new Error("useQueryParamsContext must be used within a QueryParamsProvider")
  }

  return context
}

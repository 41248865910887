import { useQueryParams } from "providers/QueryParamsProvider"
import {
  DEFAULT_TEAM_EMOJI,
  DELEGATE_TEAM_GROUPS,
  EMOJI_BG_COLORS
} from "modules/Workspace/src/core/config"
import {
  WorkspaceTeam,
  WorkspaceTeamCommunityType,
  WorkspaceTeamOption,
  WorkspaceTeamOptionsGroup
} from "modules/Workspace/src/core/types/workspace-team"
import { useModalContext } from "providers/ModalProvider"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  useGetWorkspaceTeamByIds
} from "../../../../../Workspace/src/core/hooks"
import { ContractActiveTab, ContractTabs } from "../../../components/types"
import { ContractWorkspaceTeam, IContractNormalized } from "../../types"
import { useDelegateContract } from "../useDelegateContract"
import { ModalType } from "../../../../../../providers/ModalProvider/types"

const useContractDelegate = ({
  workspaceTeams = [],
  contract,
  isMobile,
  activeTab
}: Props) => {
  const { setQueryParams, removeQueryParams } = useQueryParams()

  const { dispatch: modalDispatch, state: modalState } = useModalContext()

  const { mutateAsync: delegateContractAsync } = useDelegateContract()

  const { data: predefinedTeams = [] } = useGetWorkspaceTeamByIds(
    contract?.predefinedTeamIds || []
  )

  const [delegateTeamOptions, setDelegateTeamOptions] = useState<
    WorkspaceTeamOption[]
  >([])
  useEffect(() => {
    const newDelegateTeamOptions: WorkspaceTeamOption[] = []

    if (contract?.pk) {
      const contractTeams = [...(contract?.teams || [])].filter(
        team => !workspaceTeams.find(t => t.teamId === team.teamId)
      )

      const filteredPredefinedTeams = predefinedTeams.filter(
        predefinedTeam =>
          !contractTeams.find(
            contractTeam => contractTeam.teamId === predefinedTeam.teamId
          )
      )

      const filteredWorkspaceTeams = workspaceTeams.filter(
        team =>
          !contractTeams.find(
            contractTeam => contractTeam.teamId === team.teamId
          ) &&
          !filteredPredefinedTeams?.find(
            predefinedTeam => predefinedTeam.teamId === team.teamId
          )
      )

      const delegateContractTeamOptions = [
        ...filteredWorkspaceTeams,
        ...contractTeams,
        ...filteredPredefinedTeams
      ].map(workspaceTeam => {
        const contractTeam = contract?.teams?.find(
          team => team.teamId === workspaceTeam.teamId
        )
        const team: WorkspaceTeam | ContractWorkspaceTeam =
          contractTeam || workspaceTeam
        // make editable only user teams
        // const isEditable = contractTeam?.isEditable ?? true
        const name =
          team.communityType === WorkspaceTeamCommunityType.EMAIL
            ? team.teamId
            : team.name

        const isPredefined = !!predefinedTeams.find(
          predefinedTeam => predefinedTeam.id === team.id
        )

        return {
          id: team.id,
          name,
          emoji: team?.emoji || DEFAULT_TEAM_EMOJI,
          emojiBgColor: team?.emojiBgColor || EMOJI_BG_COLORS[7],
          type: team.type,
          isSelected: !!contractTeam,
          isPredefined,
          showIsPrivate:
            team.communityType === WorkspaceTeamCommunityType.DISCORD,
          isPrivate: ("isPrivate" in team && team.isPrivate) || false,
          isEditable: !contractTeam
        }
      })

      newDelegateTeamOptions.push(...delegateContractTeamOptions)
    }
    setDelegateTeamOptions(newDelegateTeamOptions)
  }, [
    contract?.pk,
    JSON.stringify(workspaceTeams),
    JSON.stringify(predefinedTeams)
  ])

  const delegateTeamOptionsGroups = useMemo<WorkspaceTeamOptionsGroup[]>(
    () =>
      DELEGATE_TEAM_GROUPS.map(group => {
        const teamOptions =
          delegateTeamOptions?.filter(
            team =>
              group.types.includes(team.type) &&
              group.isPredefined === team.isPredefined
          ) || []

        return {
          ...group,
          teamOptions
        }
      }),
    [JSON.stringify(delegateTeamOptions)]
  )

  const handleDelegateTeamOptionChange = (newTeam: WorkspaceTeamOption) => {
    const newTeamOptions = delegateTeamOptions.map(team => {
      if (team.id === newTeam.id) {
        return {
          ...team,
          ...newTeam
        }
      }

      return team
    })

    setDelegateTeamOptions(newTeamOptions)
  }

  const handleDelegate = useCallback(async () => {
    const delegateWorkspaceTeams = delegateTeamOptions
      .filter(team => team.isSelected)
      .map(team => ({
        id: team.id,
        isPrivate: team.isPrivate
      }))
      .filter(
        workspaceTeam =>
          !contract?.teams?.find(team => team.id === workspaceTeam.id)
      )
    await delegateContractAsync({
      page: "contract",
      contractId: contract?.id || "",
      input: {
        workspaceTeams: delegateWorkspaceTeams
      }
    })

    if (isMobile) {
      removeQueryParams("tab")
    } else {
      modalDispatch({ type: "close" })
    }
  }, [JSON.stringify(delegateTeamOptions), contract?.id, isMobile])

  const contractDelegateProps = useMemo(
    (): DelegateProps => ({
      teamOptionsGroups: delegateTeamOptionsGroups,
      onTeamOptionChange: handleDelegateTeamOptionChange,
      onDelegate: handleDelegate
    }),
    [JSON.stringify(delegateTeamOptionsGroups), handleDelegateTeamOptionChange]
  )

  const handleDelegateClick = () => {
    setQueryParams({
      tab: ContractTabs.CONTRACT_DELEGATE
    })
  }

  const isDelegateModalOpen = useMemo(
    () => modalState.modalType === "delegate",
    [modalState.modalType]
  )

  const isMobileRef = useRef(isMobile)

  useEffect(() => {
    isMobileRef.current = isMobile
  }, [isMobile])

  const closeDelegate = useCallback(() => {
    if (!isMobileRef.current && activeTab === ContractTabs.CONTRACT_DELEGATE) {
      removeQueryParams("tab")
    }
  }, [isMobileRef.current, activeTab, setQueryParams])

  useEffect(() => {
    if (
      isDelegateModalOpen &&
      (activeTab !== ContractTabs.CONTRACT_DELEGATE || isMobile)
    ) {
      modalDispatch({ type: "close" })
    }

    if (activeTab === ContractTabs.CONTRACT_DELEGATE) {
      if (!isMobile && contractDelegateProps) {
        modalDispatch({
          type: "open",
          modalType: ModalType.Delegate,
          props: contractDelegateProps,
          onClose: closeDelegate
        })
      }

      if (isDelegateModalOpen) {
        modalDispatch({
          type: "update",
          onClose: closeDelegate
        })
      }
    }
  }, [
    JSON.stringify(contractDelegateProps),
    isDelegateModalOpen,
    isMobile,
    closeDelegate,
    activeTab
  ])

  return {
    handleDelegateClick,
    contractDelegateProps
  }
}

export default useContractDelegate

interface Props {
  workspaceTeams: WorkspaceTeam[]
  contract: IContractNormalized | null
  isMobile: boolean
  activeTab: ContractActiveTab
}

export type DelegateProps = {
  teamOptionsGroups: WorkspaceTeamOptionsGroup[]
  onTeamOptionChange: (newTeam: WorkspaceTeamOption) => void
  onDelegate: () => void
}

import { usePlatformWorkspace } from "modules/Workspace/src/core/hooks"
import React from "react"
import {
  ContractChat,
  ContractCreatePage,
  PlatformContractDelegatePage,
  PlatformContractSettingsPage
} from "../../modules/Contract/src/components"
import WorkspaceDesktop from "./WorkspaceDesktop"
import ContractPage from "./WorkspaceMobile/ContractPage"
import ContractsListPage from "./WorkspaceMobile/ContractsListPage"
import Layout from "./WorkspaceMobile/Layout"
import MainLayout
  from "../../modules/Workspace/src/components/layouts/MainLayout"
import {
  usePlatformWorkspaceContent
} from "../../providers/PlatformWorkspaceProvider"

function PlatformWorkspace() {
  const {
    contractSidebarProps,
    contractDetailsProps,
    contractDelegateProps,
    handleContractCreateClick,
    handleContractCreate,
    contractSettingsProps,
    isMobile,
    activeTab,
    platformConfig,
    user,
    handleChatBackClick
  } = usePlatformWorkspace()

  const { contractDetails } = usePlatformWorkspaceContent()

  if (!isMobile) {
    return (
      <WorkspaceDesktop
        contractSidebarProps={contractSidebarProps}
        contractDetailsProps={contractDetailsProps}
        onContractCreateClick={handleContractCreateClick}
        platformConfig={platformConfig}
        user={user}
        activeTab={activeTab}
        onChatBackClick={handleChatBackClick}
      />
    )
  }

  switch (activeTab) {
    case "contract":
      return (
        <ContractPage
          platformConfig={platformConfig}
          contractDetailsProps={contractDetailsProps}
        />
      )
    case "contract-create":
      return <ContractCreatePage onCreate={handleContractCreate} />
    case "contract-delegate":
      return <PlatformContractDelegatePage {...contractDelegateProps} />
    case "contract-settings":
      return <PlatformContractSettingsPage {...contractSettingsProps} />
    case "chat":
      return (
        <MainLayout>
          <ContractChat
            user={user} onBackClick={handleChatBackClick}
            contract={contractDetailsProps.contract}
            heading={contractDetails.chatSectionHeading}
            placeholder={contractDetails.chatInputPlaceholder}
          />
        </MainLayout>
      )
    default:
      return (
        <ContractsListPage
          contractSidebarProps={contractSidebarProps}
          onContractCreateClick={handleContractCreateClick}
          platformConfig={platformConfig}
        />
      )
  }
}

export default PlatformWorkspace

import {
  useGetPlatformWorkspace
} from "modules/Platform/src/hooks"
import { useSignInWithToken, useSignOut } from "modules/User/src/core/hooks"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  useCurrentAuthenticatedUser
} from "../../../User/src/core/hooks/useCurrentAuthenticatedUser"
import { ICognitoUser } from "../../../User/src/core/types"

const usePlatformWorkspaceConfig = () => {
  const { platformWorkspaceConfigId = "" } = useParams()
  const [platformDomain, setPlatformDomain] = useState("")
  const [postMessageData, setPostMessageData] = useState({
    email: "",
    token: "",
    platformHostname: ""
  })
  const [startLoading, setStartLoading] = useState(false)
  const {
    data: cognitoUser,
    isLoading: isLoadingCognitoUser
  } = useCurrentAuthenticatedUser() as {
    data: ICognitoUser,
    isLoading: boolean
  }

  // show in local time
  // console.log("startLoading at ", new Date().toLocaleString(), {
  //   startLoading,
  //   userEmail: cognitoUser?.attributes?.email
  // })
  const {
    data: {
      platformWorkspaceConfig,
      workspace
    } = {}
  } = useGetPlatformWorkspace(
    {
      platformDomain,
      platformWorkspaceConfigId
    },
    {
      enabled: startLoading
    }
  )
  // console.log("workspace data ", {
  //   platformWorkspaceConfig,
  //   workspace
  // })

  // const { mutateAsync: checkIfUserExistsInPlatformConfigAsync } =
  //   useCheckIfUserExistsInPlatformConfig()
  const { mutateAsync: signInWithToken } = useSignInWithToken()
  const { mutateAsync: signOutAsync } = useSignOut()

  useEffect(() => {
    const handlePostMessage = async (event: MessageEvent) => {
      // console.log("handlePostMessage@postMessage event", event)
      const { token, email } = event.data
      // console.log("handlePostMessage@data : ", event.data)
      if (!email || !token) {
        return
      }

      const platformHostname = new URL(event.origin).hostname
      setPlatformDomain(platformHostname)
      setPostMessageData({ email, token, platformHostname })
    }

    window.addEventListener("message", handlePostMessage)

    return () => {
      window.removeEventListener("message", handlePostMessage)
    }
  }, [])


  useEffect(() => {
    // console.log(new Date().toLocaleString() + "useEffect@postMessageData", {
    //   postMessageData,
    //   cognitoEmail: cognitoUser?.attributes?.email,
    //   isLoadingCognitoUser
    // })
    // console.log("useEffect@postMessageData : ", {
    //   postMessageData,
    //   cognitoEmail: cognitoUser?.attributes?.email,
    //   isLoadingCognitoUser
    // })

    if (!postMessageData.email?.trim() || isLoadingCognitoUser) {
      return
    }
    // console.log("startLoading", startLoading)

    (async () => {
      if (!cognitoUser?.attributes?.email || cognitoUser?.attributes?.email !== postMessageData.email) {
        // console.log(new Date().toLocaleString() + "useEffect@signout start")
        await signOutAsync()
        await signInWithToken({
          email: postMessageData.email,
          token: postMessageData.token
        })
      }
      setStartLoading(true)
    })()
  }, [cognitoUser?.attributes?.email, isLoadingCognitoUser, postMessageData.email])

  return {
    platformWorkspaceConfig,
    workspace
  }
}

export default usePlatformWorkspaceConfig

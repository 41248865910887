const stylesTheme = {
  styles: {
    global: {
      body: {
        letterSpacing: "-0.3px",
        color: "primaryDark",
        fontWeight: "medium"
      },
      ".h-screen": {
        height: "100vh",
        "@supports (height: 100dvh)": {
          height: "100dvh"
        }
      },
      ".chakra-alert ": {
        rounded: "10px !important",
        "--alert-bg": "#2eb53b !important"
      }
    }
  }
}

export default stylesTheme

import {
  Box,
  ButtonGroup,
  Textarea
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import { Button } from "theme/components"
import { ContractSectionLayout } from "./index"

const ContractDescription = ({
  defaultValue,
  onSubmit,
  isEditable = true,
  heading,
  placeholder,
  saveButtonLabel,
  cancelButtonLabel,
  contractId
}) => (
  <ContractSectionLayout
    iconName="docs"
    heading={heading || "Description"}
  >
    <DescriptionField
      defaultValue={defaultValue}
      onSubmit={onSubmit}
      isEditable={isEditable}
      placeholder={placeholder}
      saveButtonLabel={saveButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      contractId={contractId}
    />
  </ContractSectionLayout>
)

export default ContractDescription

function DescriptionField({
  defaultValue,
  onSubmit,
  isEditable = true,
  placeholder = "Add a description",
  saveButtonLabel = "Save",
  cancelButtonLabel = "Cancel",
  contractId,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(defaultValue || "")
  const [initialValue, setInitialValue] = useState(defaultValue || "") // Store initial value for cancel
  const textAreaRef = useRef(null)

  const textAreaAdjust = (event) => {
    setTimeout(() => {
      const enterHeight = event.key === "Enter" ? 5.25 : 0
      event.target.style.height = "1px"
      event.target.style.height = event.target.scrollHeight + "px"
    }, 0)
  }

  useEffect(() => {
    setValue(defaultValue || "")
    setInitialValue(defaultValue || "")
  }, [defaultValue])

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaAdjust({ target: textAreaRef.current })
    }
  }, [textAreaRef.current])

  useEffect(() => {
    setValue(defaultValue || "")
    setInitialValue(defaultValue || "")
    setIsEditing(false)
    textAreaAdjust({ target: textAreaRef.current })
  }, [contractId])


  const handleSave = () => {
    setIsEditing(false)
    onSubmit && onSubmit(value.trimEnd())

    textAreaAdjust({ target: textAreaRef.current })
  }

  const handleCancel = () => {
    setIsEditing(false)
    setValue(initialValue)
    textAreaAdjust({ target: textAreaRef.current })
  }

  return (
    <Box ml="-1">
      <Textarea
        ref={textAreaRef}
        value={value}
        spellCheck={isEditing}
        disabled={!isEditable}
        onChange={(e) => setValue(e.target.value)}
        resize="none"
        fontSize="sm"
        onKeyDown={textAreaAdjust}
        bg={isEditing || !value.trim().length ? "lightBlue" : "white"}
        onBlur={e => e.preventDefault()}
        onFocus={() => isEditable && setIsEditing(true)}
        fontWeight="medium"
        border="none"
        px="3"
        py="3"
        minHeight="0"
        rounded="12px"
        _focus={{
          border: "none",
          boxShadow: "none"
        }}
        _hover={{
          bgColor: "lightBlue"
        }}
        _disabled={{
          bg: "white"
        }}
        _placeholder={{
          color: "rgba(48, 48, 48, 0.6)"
        }}
        placeholder={placeholder}
        {...props}
      />

      <ButtonGroup
        justifyContent="end"
        w="full"
        mt="2"
        spacing="3"
        display={isEditing ? "inline-flex" : "none"}
      >
        <Button variant="secondary" size="sm" onClick={handleCancel}>
          {cancelButtonLabel}
        </Button>

        <Button size="sm" onClick={handleSave}>
          {saveButtonLabel}
        </Button>
      </ButtonGroup>
    </Box>
  )
}


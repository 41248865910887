import { useQueryParams } from "providers/QueryParamsProvider"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { WorkspaceActiveTab } from "../../types/global"
import { useGetWorkspace, useGetWorkspaceTeams } from "../index"
import { useWorkspaceSettings } from "../index"

const useWorkspaceActions = (workspaceId: string) => {
  const { queryParams } = useQueryParams()
  const navigate = useNavigate()

  const { data: workspace = null } = useGetWorkspace(workspaceId, {
    enabled: !!workspaceId
  })

  const { data: getWorkspaceTeamsResponse } = useGetWorkspaceTeams(
    { input: { workspaceId } },
    {
      enabled: !!workspaceId
    }
  )

  const activeTab = useMemo<WorkspaceActiveTab>(
    () => queryParams.tab as WorkspaceActiveTab,
    [queryParams.tab]
  )

  const {
    workspaceDeleteProps,
    openWorkspaceDeleteModal,
    handleArchivedContractsClick,
    handleArchivedContractsBackClick
  } = useWorkspaceSettings({
    workspaceId: workspaceId,
    activeTab
  })

  const navigateToWorkspace = () => navigate("./")


  return {
    workspace,
    workspaceTeams: getWorkspaceTeamsResponse?.items || [],
    activeTab,
    workspaceDeleteProps,
    openWorkspaceDeleteModal,
    navigateToWorkspace,
    handleArchivedContractsClick,
    handleArchivedContractsBackClick
  }
}

export default useWorkspaceActions

import { useQueryParams } from "providers/QueryParamsProvider"
import {
  useSoftDeleteContract,
  useUpdateContract,
  useWorkspaceContractSubscriptions,
  useGetMappedContract, useChildContracts
} from "modules/Contract/src/core/hooks"
import {
  contractUpdateInputNormalizedType,
  IContractNormalized,
  IContractNormalizedWithLoading
} from "modules/Contract/src/core/types"
import { useMessagesSubscriptions } from "modules/Message/src/core/hooks"
import useContractMessages
  from "modules/Message/src/core/hooks/useContractMessages"
import { useGetCurrentUser } from "modules/User/src/core/hooks"
import {
  useGetWorkspaceTeams
} from "modules/Workspace/src/core/hooks"
import { EmptyObject, Workspace } from "modules/Workspace/src/core/types"
import { useIsMobile } from "providers/BreakpointProvider"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { ContractActiveTab } from "../../../components/types"
import { useContractResources } from "../index"
import useWorkspaceContractDelegate from "./delegate"
import useWorkspaceContractGitHub from "./github"
import useWorkspaceContractJira from "./jira"
import usePlatformContractSettings from "./settings"
import { PlatformWorkspaceConfig } from "../../../../../Platform/src/core/types"

const usePlatformWorkspaceContract = ({
  workspace,
  platformWorkspaceConfig,
  workspaceContracts
}: Props) => {
  const { queryParams, setQueryParams, removeQueryParams } = useQueryParams()

  const activeTab = useMemo<ContractActiveTab>(
    () => queryParams.tab as ContractActiveTab,
    [queryParams.tab]
  )

  const {
    mutateAsync: softDeleteContractAsync,
    isLoading: isLoadingDeleteContract
  } = useSoftDeleteContract()
  const { mutateAsync: updateContractAsync } = useUpdateContract()

  // user
  const { data: user = null } = useGetCurrentUser()

  // user

  const { data: getWorkspaceTeamsResponse } = useGetWorkspaceTeams(
    { input: { workspaceId: workspace.id } },
    {
      enabled: !!workspace.id
    }
  )

  // workspace

  // update completed
  const toggleCompleted = async (contractId: string) => {
    const contract = workspaceContracts.find(
      contract => contract.id === contractId
    )
    await updateContractAsync({
      workspaceId: workspace.id,
      input: {
        id: contractId,
        completed: !contract?.completed
      }
    })
  }

  // update completed

  // update pointEstimation
  const handlePointEstimationChange = async (
    contractId: string,
    pointEstimation: number
  ) => {
    await updateContractAsync({
      workspaceId: workspace.id,
      input: {
        id: contractId,
        pointEstimation
      }
    })
  }

  // update pointEstimation

  // active contract
  const isMobile = useIsMobile()
  const contractPk = useMemo(
    () => queryParams.contractPk || "",
    [queryParams.contractPk]
  )

  const workspaceContract = useMemo(() => {
    if (contractPk) {
      return (
        workspaceContracts.find(contract => contract.pk === contractPk) || null
      )
    }

    return null
  }, [JSON.stringify(workspaceContracts), contractPk])

  const {
    data: contract
  } = useGetMappedContract(workspaceContract?.id || "", {
    enabled: !!workspaceContract?.id,
    keepPreviousData: false,
    initialData: workspaceContract
  }) as { data: IContractNormalized | null }

  const { contractResources, handleResourcesUpload } =
    useContractResources(contractPk)

  const { contractMessages, handleCommentCreate } = useContractMessages(
    user,
    contract?.sourceContractPk ? contract : null
  )
  const jiraProps = useWorkspaceContractJira({
    contract,
    workspace: workspace || {}
  })
  const githubProps = useWorkspaceContractGitHub({
    user,
    contract
  })
  const { handleDelegateClick, contractDelegateProps } =
    useWorkspaceContractDelegate({
      workspace: workspace || {},
      workspaceContracts: workspaceContracts,
      workspaceTeams: getWorkspaceTeamsResponse?.items || []
    })

  const {
    handleChildContractCreate,
    handleChildContractCompleteToggle,
    handleChildContractClick,
    childContracts
  } = useChildContracts(
    workspaceContract || {},
    true
  )

  // contract settings
  const handleContractSuccessDelete = () => removeQueryParams("tab")

  const { handleContractSettingsClick, contractSettingsProps } =
    usePlatformContractSettings({
      contract,
      activeTab,
      onSuccessDelete: handleContractSuccessDelete,
      platformWorkspaceConfig
    })
  // contract settings

  const contractTeamOptions = useMemo(() => {
    if (!contract) {
      return []
    }

    return (
      getWorkspaceTeamsResponse?.items.map(team => ({
        label: team.name,
        value: team.id
      })) || []
    )
  }, [contract, getWorkspaceTeamsResponse?.items.length])

  const handleContractUpdate = async (
    input: contractUpdateInputNormalizedType
  ) => {
    await updateContractAsync({
      workspaceId: workspace.id,
      input: {
        id: contract?.id || "",
        ...input
      }
    })
  }

  const handleContractDeleteClick = async () => {
    await softDeleteContractAsync({
      contractId: contract?.id || "",
      workspaceId: workspace.id
    })
  }

  const navigate = useNavigate()
  const handleBackCLick = () => removeQueryParams("tab")
  const handleChatClick = () => setQueryParams({ tab: "chat" })

  const contractDetailsProps = useMemo(() => {
    if (!contract) {
      return {}
    }

    return {
      contract: contract,
      teamOptions: contractTeamOptions,
      onDelegateClick: handleDelegateClick,
      onContractSettingsClick: handleContractSettingsClick,
      workspace,
      toggleCompleted,
      onUpdate: handleContractUpdate,
      messages: contractMessages,
      onCommentCreate: handleCommentCreate,
      resources: contractResources,
      onResourcesUpload: handleResourcesUpload,
      jiraProps,
      githubProps,
      onBackClick: isMobile && handleBackCLick,
      onDeleteContractClick: handleContractDeleteClick,
      isLoadingDeleteContract,
      onChildContractCreate: handleChildContractCreate,
      onChildContractCompleteToggle: handleChildContractCompleteToggle,
      onChildContractClick: handleChildContractClick,
      childContracts,
      onChatClick: handleChatClick,
    }
  }, [
    JSON.stringify(contract),
    contractTeamOptions,
    workspace,
    contractMessages.length,
    toggleCompleted,
    isLoadingDeleteContract,
    childContracts?.length
  ])

  // active contract

  // subscriptions
  useMessagesSubscriptions(contract?.pk || "", user?.id || "")
  useWorkspaceContractSubscriptions(workspace.id)

  // subscriptions

  return {
    toggleCompleted,
    handlePointEstimationChange,
    contractDetailsProps,
    contractPk,
    handleDelegateClick,
    contractDelegateProps,
    handleContractSettingsClick,
    contractSettingsProps
  }
}

export default usePlatformWorkspaceContract

type Props = {
  workspace: Workspace | EmptyObject,
  workspaceContracts: IContractNormalizedWithLoading[],
  platformWorkspaceConfig: PlatformWorkspaceConfig | EmptyObject
}
